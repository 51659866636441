body {
  padding: 0 !important;
  margin: 0 !important;
}
.fundo-login {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url('./static/img/jmb-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Numans', sans-serif;
}

.container{
  height: 100%;
  align-content: center;
}

.card-login{
  margin-top: auto;
  margin-bottom: auto;
  background-color: rgba(0,0,0,0.2) !important;
}

.label{
  color: white;
}