.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-wrap-not-found {
  min-height: 100vh;
}

.body-not-found {
  background: #e6e6e6;
}

h4 {
  color: #2E8B57;
}

.Table{
  height: 250px;       /* Just for the demo          */  
  overflow-y: auto;    /* Trigger vertical scroll    */
  overflow-x: auto;  /* Hide the horizontal scroll */  
  width: auto;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.buttonEditComprador {
  width: 80px;
  margin-bottom: 5px;
}

.buttonEditReserva {
  width: 80px;
  margin-bottom: 5px;
}

.buttonPrintReserva {  
  width: 80px;
  margin-bottom: 5px;  
}

.background-page-main {
  height: 100%;
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;    
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
